<template>
  <BaseModal :to-bottom="!xlAndLarger" content-transition="vfm-slide-up">
    <div :class="classes.filial">
      <div v-if="currentCity" :class="classes.city">
        {{ currentCity.services_count }}
        {{
          declension(currentCity.services_count, [
            'филиал',
            'филиала',
            'филиалов',
          ])
        }}
        в
        <BaseButton
          intent="link"
          underline="on"
          class="!inline !w-auto"
          @click="changeCity"
        >
          г. {{ currentCity.name }}
        </BaseButton>
      </div>
      <div :class="classes.serviceCenter">
        <div
          v-for="(serviceCenter, index) in serviceCenters"
          :key="index"
          class="flex"
        >
          <Icon
            name="common:location"
            size="11"
            :class="classes.icon"
          />
          <div class="text-[14px]/[21px]">
            <div>
              {{ serviceCenter.anchor }}
            </div>
            <BaseButton
              intent="link"
              underline="on"
              class="text-ghost"
              @click="showInModalServiceCenter(serviceCenter)"
            >
              Подробнее
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { cx } from 'cva'
import { storeToRefs } from 'pinia'
import type { ServiceCenter } from '~/types/models'
import { declension } from '@/utils'

const emit = defineEmits(['serviceCenterSelected', 'showCities'])

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const citiesStore = useCitiesStore()
const { current: currentCity } = storeToRefs(citiesStore)

const serviceCentersStore = useServiceCentersStore()
const { list: serviceCenters } = storeToRefs(serviceCentersStore)

const showInModalServiceCenter = (serviceCenter: ServiceCenter) => {
  serviceCentersStore.shownInModal = serviceCenter
  emit('serviceCenterSelected')
}

const changeCity = () => {
  emit('showCities')
}

const classes = {
  filial: cx(['min-w-full', 'p-4', 'xl:min-w-[303px]', 'xl:pr-[30px]', 'pb-5']),
  city: cx(['text-[20px]/[30px]', 'mb-5']),
  icon: cx(['!text-yellow', 'mt-[5px]', 'mr-[15px]']),
  serviceCenter: cx(['grid', 'gap-5', 'max-h-[50vh]', 'py-1', 'overflow-auto']),
}
</script>